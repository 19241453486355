import React from 'react'
import Layout from '../components/Layout'
import { graphql, Link } from 'gatsby'
import { LazyBlocksFactory } from '../components/LazyBlocksFactory/LazyBlocksFactory'
import { Container } from '@material-ui/core'
import { map } from 'lodash'
import styled from 'styled-components'
import Arrow from '../images/arrCarousel.svg'
import { appendUrlPartnerTracking } from '../util/url'
interface Props {
  data: any
  pageContext: any
}

const Careers = ({ data, pageContext }: Props) => {
  const { wpPage } = data
  const {allWpCareer} = data
  const careers = allWpCareer.edges.map(v=> v.node)
  // console.log(careers)

  // const lazyBlocks = parse(wpPage.lazy_data)
  const renderedBlocks = LazyBlocksFactory(wpPage.lazy_data, wpPage.title)
  // console.log(renderedBlocks)
  return <Layout>{renderedBlocks.map((v) => v)}
  
  <Container maxWidth={"lg"}>
  <GridLayout>
    {careers.map(v=>{
      return(
        <CardContainer to={appendUrlPartnerTracking("/careers/" + v.slug)}>
              <h5>
                {v.title}
              </h5>
              <StyledArrow src={Arrow} />
        </CardContainer>
      )
    })}
  </GridLayout>
  </Container>
  </Layout>
}
export default Careers

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr ;
    }
  padding-bottom:20px;
`
const CardContainer = styled(Link)`
  background-color: #FAFAFA;
  border-top: 3px solid #00A3C7;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  /* padding-bottom: 30px; */
  display: flex;
  flex-direction: column;
  justify-content:space-between;
  height: 180px;
 &:hover{
    text-decoration: none;
  }
  
`
const StyledArrow = styled.img`
  max-width: 48px;
  padding-bottom: 32px;
  align-self: flex-end;
  `
export const pageQuery = graphql`
  query careers {
    wpPage(title: { eq: "Careers" }) {
      id
      lazy_data
      title
    }
    allWpCareer {
      edges {
        node {
          id
          title
          slug
        }
      }
    }
  }
`
